import { gql } from '@apollo/client';

export const CREATE_PRODUCT_ADMIN = gql`
  mutation createProductAdmin($input: inputCreateProductAdmin) {
    createProductAdmin(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_STATE_PRODUCT = gql`
  mutation updateStateProduct($input: updateStateProduct) {
    updateStateProduct(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_PRODUCT_ADMIN = gql`
  mutation updateProductsDetailsAdmin($input: inputProductUpdate) {
    updateProductsDetailsAdmin(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_ROUTINE = gql`
  mutation insertRoutine($input: insertRoutineInput) {
    insertRoutine(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_STATUS_TRAINING = gql`
  mutation saveStatusTraining($input: statusTraining) {
    saveStatusTraining(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_STATUS_TRAINING_CAAS = gql`
  mutation saveStatusTrainingCaaS($input: statusTraining) {
    saveStatusTrainingCaaS(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SET_TRAINING_COMPLETE = gql`
  mutation setTrainingComplete($idUser: Int, $idTraining: Int) {
    setTrainingComplete(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SET_TRAINING_COMPLETE_CAAS = gql`
  mutation setTrainingCompleteCaaS($idUser: Int, $idTraining: Int) {
    setTrainingCompleteCaaS(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_RELROUTINE = gql`
  mutation createRelRoutine($input: createcreateRelRoutineInput) {
    createRelRoutine(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_UPDATE_TRAINING = gql`
  mutation createUpdateTraining($input: createUpdateTrainingInput) {
    createUpdateTraining(input: $input) {
      message
      response
      statusCode
      error
    }
  }
`;

export const UPDATE_STATUS_TRAINING_SUCCESS = gql`
  mutation updateStatusTrainingSuccess($input: successStatusTraining) {
    updateStatusTrainingSuccess(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ENABLE_DISABLE_TRAINING = gql`
  mutation enableDisableTraining($input: enableDisableTrainingInput) {
    enableDisableTraining(input: $input) {
      statusCode
      message
    }
  }
`;

export const SHOW_HIDE_TRAINING = gql`
  mutation showHideTraining($input: showHideTrainingInput) {
    showHideTraining(input: $input) {
      statusCode
      message
    }
  }
`;

export const UPDATE_USER_TRAINING = gql`
  mutation updateUserTraining(
    $idUser: Int!
    $idTraining: Int!
    $status: Boolean
  ) {
    updateUserTraining(
      idUser: $idUser
      idTraining: $idTraining
      status: $status
    )
  }
`;

export const UPDATE_NEXT_PLAN = gql`
  mutation insertCatRelTrainingKandaUsers($idPlan: Int!, $idUser: Int!) {
    insertCatRelTrainingKandaUsers(idPlan: $idPlan, idUser: $idUser) {
      statusCode
      error
      message
    }
  }
`;

export const SET_DIAGNOSIS_MADE = gql`
  mutation setDiagnosisMade($diagnosisMade: Int!, $idUser: Int!) {
    setDiagnosisMade(diagnosisMade: $diagnosisMade, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_PLANS_USERS = gql`
  mutation insertCatRelPlansUsers(
    $idUser: Int!
    $idRoad: Int!
    $diagnostic: Int!
  ) {
    insertCatRelPlansUsers(
      idUser: $idUser
      idRoad: $idRoad
      diagnostic: $diagnostic
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ADD_SCORE_TO_USER = gql`
  mutation addScoreToUser($idUser: Int!, $score: Int!) {
    addScoreToUser(idUser: $idUser, score: $score)
  }
`;

export const GENERATE_SHORTENED_LINK = gql`
  mutation generateShortendLink($input: inputgenerateShortendLink) {
    generateShortendLink(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const UPDATE_VALUE_IDTYPE = gql`
  mutation updateValueIDType($idUser: Int!, $input: idTypesInput) {
    updateValueIDType(idUser: $idUser, input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_UPDATE = gql`
  mutation dynamicUpdate($input: dynamicUpdateInput) {
    dynamicUpdate(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_GET = gql`
  mutation dynamicGet($input: dynamicGetInput) {
    dynamicGet(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DYNAMIC_INSERT = gql`
  mutation dynamicInsert($input: dynamicinsertInput) {
    dynamicInsert(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation dynamicMongoSession($input: mongoInput) {
    dynamicMongoSession(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const UPDATE_HISTORY_COLLECTION = gql`
  mutation dynamicUpdateHistoryMongo($input: mongoUpdateHistoryInput) {
    dynamicUpdateHistoryMongo(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_SESSION_USER = gql`
  mutation updateSessionUser($idUser: String!, $Flag: Boolean) {
    updateSessionUser(idUser: $idUser, Flag: $Flag) {
      statusCode
      message
      error
      response
    }
  }
`;
export const INSERT_SESSION_USER = gql`
  mutation insertSessionUser($idUser: String!, $Flag: Boolean) {
    insertSessionUser(idUser: $idUser, Flag: $Flag) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_OPPORTUNITIES = gql`
  mutation createOpportunities($input: OpportunityDetailInput) {
    createOpportunities(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const WEB_SERVICE_CI = gql`
  mutation webServiceCI($input: dataWebServiceCI) {
    webServiceCI(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: sentNotificationInput) {
    sendNotification(input: $input) {
      statusCode
      message
      response
      error
    }
  }
`;

export const GET_QUESTIONS_BY_IDCATFIELD = gql`
  mutation getQuestionsByIDCatField(
    $IDCatField: Int!
    $IDRoutine: Int!
    $QuestionsOrder: Int!
  ) {
    getRoadflowByField(
      IDCatField: $IDCatField
      IDRoutine: $IDRoutine
      QuestionsOrder: $QuestionsOrder
    ) {
      response
    }
  }
`;

export const INSERT_NEW_USER = gql`
  mutation insertNewUserKanda($data: String) {
    insertNewUserKanda(data: $data) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_COTIZAR_INTERPROT = gql`
  mutation getQuoteInterptoteccion($dataJson: String) {
    getQuoteInterptoteccion(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_COTIZAR_AUTOCOMPARA = gql`
  mutation getQuoteAutocompara($dataJson: String) {
    getQuoteAutocompara(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_COTIZAR_AHORRASEGUROS = gql`
  mutation getQuoteAhorraSeguro($dataJson: String) {
    getQuoteAhorraSeguro(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_COTIZAR_SEGUROINTELIGENTE = gql`
  mutation getQuoteSeguroInteligente($dataJson: String) {
    getQuoteSeguroInteligente(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_COTIZAR_GUROS = gql`
  mutation getQuoteGuros($dataJson: String) {
    getQuoteGuros(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SAVE_CALCULATION_FIELDS = gql`
  mutation saveCalculationFields($input: investmentSimulatorInput) {
    saveCalculationFields(input: $input) {
      response
    }
  }
`;

export const ADD_SCORE_AC = gql`
  mutation insertScoreAC($idUser: Int!, $flag: Int!) {
    insertScoreAC(idUser: $idUser, flag: $flag) {
      statusCode
      message
      error
      response
    }
  }
`;

export const ADD_SCORE_WO = gql`
  mutation insertScoreWO($idUser: Int!, $idWO: Int!) {
    insertScoreWO(idUser: $idUser, idWO: $idWO) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_INFO_USER_SCORE = gql`
  mutation getInfoScore($idCont: Int!, $idUser: Int!) {
    getInfoScore(idCont: $idCont, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_CREZE = gql`
  mutation registerCreze($input: registerCrezeInput) {
    registerCreze(input: $input) {
      statusCode
      message
      error
      response {
        idTicket
        message
        tipoCliente
        nPass
        preAppId
        profileId
        url
      }
    }
  }
`;

export const INSERT_PARTNER_UTM = gql`
  mutation insertPartnerByUTM($input: inputPartnerUtm!) {
    insertPartnerByUTM(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_SESSION_UTM = gql`
  mutation updateSessionUtmFields($input: inputSessionUtmFields!) {
    updateSessionUtmFields(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const LOGIN_KANDA_ADMIN = gql`
  mutation loginKandaAdmin($input: LoginInput) {
    loginKandaAdmin(input: $input) {
      idCont
      idUser
      idCatRelAction
      firstName
      middleName
      fathersLastName
      mothersLastName
      idSession
      token
      ProfilePicture
      type
      BirthDate
      Gender
      diagnostic
    }
  }
`;

export const LOGIN_KANDA = gql`
  mutation loginKanda($input: LoginInput) {
    loginKanda(input: $input) {
      idCont
      idUser
      idCatRelAction
      firstName
      middleName
      fathersLastName
      mothersLastName
      idSession
      token
      ProfilePicture
      type
      BirthDate
      Gender
      diagnostic
    }
  }
`;

export const DYNAMIC_GET_PROPERTY_MONGO = gql`
  mutation dynamicGetPropertyMongo($properties: String, $collection: String) {
    dynamicGetPropertyMongo(properties: $properties, collection: $collection) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_HUBSPOT_INFO = gql`
  mutation sendInfoHubForm($dataJson: String) {
    sendInfoHubForm(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_AUTHENTICATE_BC = gql`
  mutation callAuthenticator($input: personalInformation) {
    callAuthenticator(input: $input) {
      RespuestaBC
      BCScore
      RespComentario
      FechaAut
      activeCard
      digits
      mortgageCredit
      automotiveCredit
      authenticationSavings
    }
  }
`;

export const UPDATE_HISTORY_COLLECTION_BILLY = gql`
  mutation dynamicUpdateHistoryCaaSBilly($input: mongoUpdateHistoryInput) {
    dynamicUpdateHistoryCaaSBilly(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CURP = gql`
  mutation createCurp($input: inputCURP) {
    createCurp(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_RFC = gql`
  mutation createRfc($input: RFCInput) {
    createRfc(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CI_SERVICES = gql`
  mutation ServiceCI(
    $cadena: String
    $correo: String
    $dataSegurify: SegurifyData
  ) {
    ServiceCI(cadena: $cadena, correo: $correo, dataSegurify: $dataSegurify) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_DAT_VIRALITY = gql`
  mutation insertDatVirality($input: ViralityParams) {
    insertDatVirality(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const PUNTOS_CAASSCOTIA_V2 = gql`
  mutation PuntosScotiabank($IDuser: Int!, $type: String!) {
    PuntosScotiabank(IDuser: $IDuser, type: $type) {
      statusCode
      message
      error
      response
      cardInfo
    }
  }
`;

export const INSERT_ACCOUNT_SAVING = gql`
  mutation insertAccountSaving($input: AccountSaving) {
    insertAccountSaving(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const INSERT_MOVEMENT_SAVING = gql`
  mutation insertMovementSaving($input: MovementSaving) {
    insertMovementSaving(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_TRANSACTION_SAVING = gql`
  mutation createTransaction($input: TransactionSaving) {
    createTransaction(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_CLIENT = gql`
  mutation registerClient($input: RegisterInput) {
    registerClient(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_HEARTS = gql`
  mutation registerHearts($value: String, $IDUser: String) {
    registerHearts(value: $value, IDUser: $IDUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_STATUS_HOME = gql`
  mutation registerStatusVisitHome($idUser: Int!, $idTraining: Int!) {
    registerStatusVisitHome(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;
export const REGISTER_PYME_DELTAI = gql`
  mutation registerPyMEDeltai($dataJson: String) {
    registerPyMEDeltai(dataJson: $dataJson) {
      statusCode
      message
      error
      response
    }
  }
`;

export const COUNT_MODAL_VIEWS = gql`
  mutation dynamicMongoSession($input: mongoInput) {
    dynamicMongoSession(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_CREDITEA_WL = gql`
  mutation sendCrediteaApplicationWL($input: inputCreditea!) {
    sendCrediteaApplicationWL(input: $input) {
      statusCode
      message
      error
      response
      responseRaw
      url
    }
  }
`;

export const SEND_OTP_CREDITEA_WL = gql`
  mutation sendOTPCrediteaApplicationWL(
    $OTP: String!
    $id: String!
    $email: String!
  ) {
    sendOTPCrediteaApplicationWL(OTP: $OTP, id: $id, email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const RE_SEND_OTP_CREDITEA_WL = gql`
  mutation reSendOTPCrediteaApplicationWL($id: String!, $email: String!) {
    reSendOTPCrediteaApplicationWL(id: $id, email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_KUBO_FINANCIERO = gql`
  mutation sendInfoKubo($input: requestKubo) {
    sendInfoKubo(input: $input) {
      statusCode
      message
      error
      response {
        estatus
        mensaje
        url_redirect
        preaprobado
        prospectus_id
        bcscore
      }
    }
  }
`;
export const SEND_ESCAMPA_INFO = gql`
  mutation sendEscampaApplication($input: inputEscampa) {
    sendEscampaApplication(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_DATA_KUBO_FINANCIERO = gql`
  mutation registerDataApiKubo($input: requestKubo) {
    registerDataApiKubo(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_MAGIC_LINK = gql`
  mutation createMagicLink($text: String!, $origin: String!) {
    createMagicLink(text: $text, origin: $origin) {
      statusCode
      message
      response
      error
    }
  }
`;

export const SAVE_LIFE_HEALTH_BILLY_DATA = gql`
  mutation saveLifeHealthBillyData($input: trainingData) {
    saveLifeHealthBillyData(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_CHUBB_INFO = gql`
  mutation sendChubbInfo($input: requestChubbPolicy) {
    sendChubbInfo(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_ID_VIRALITY = gql`
  mutation UpdateIdVisibilityRewards($idVirality: Int!) {
    UpdateIdVisibilityRewards(idVirality: $idVirality) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_LEADS_AHORRO = gql`
  mutation sendMailretiro($input: sendMailRetiroInput) {
    sendMailretiro(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SEND_IKE_INFO = gql`
  mutation SendScrapperLockton($dataJson: String!, $infoProduct: String!) {
    SendScrapperLockton(dataJson: $dataJson, infoProduct: $infoProduct) {
      statusCode
      message
      error
      response
    }
  }
`;

export const UPDATE_INITIAL_STATE = gql`
  mutation UpdateInitialBillySDS($input: InputUpdateInitialBillySDS) {
    UpdateInitialBillySDS(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const REGISTER_DALEFON = gql`
  mutation registerDalefon($Name: String!, $Phone: String!) {
    registerDalefon(Name: $Name, Phone: $Phone) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CHECK_IMEI_DALEFON = gql`
  mutation checkImeiDalefon($BToken: String!, $Imei: String!) {
    checkImeiDalefon(BToken: $BToken, Imei: $Imei) {
      statusCode
      message
      error
      response
    }
  }
`;

export const SELECT_OFFER_DALEFON = gql`
  mutation seleccionarOfertaDalefon($BToken: String!, $product_sku: String!) {
    seleccionarOfertaDalefon(BToken: $BToken, product_sku: $product_sku) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DIRECCION_ENVIO_DALEFON = gql`
  mutation direccionEnvioDalefon(
    $BToken: String!
    $address: String!
    $neighborhood_id: Float!
    $contact_phone: String!
  ) {
    direccionEnvioDalefon(
      BToken: $BToken
      address: $address
      neighborhood_id: $neighborhood_id
      contact_phone: $contact_phone
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const COMPRA_SIM_COMPLETO_DALEFON = gql`
  mutation compraSIMCompletoDalefon(
    $BToken: String!
    $input: InputCompraSIMDalefon
  ) {
    compraSIMCompletoDalefon(BToken: $BToken, input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CONFIRM_PAY_DALEFON = gql`
  mutation confirmarPagoDalefon($BToken: String!) {
    confirmarPagoDalefon(BToken: $BToken) {
      statusCode
      message
      error
      response
    }
  }
`;
