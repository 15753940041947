import { lazy } from 'react';

const HomeCaaS = lazy(() =>
  import('../../kanda/categories/Caas/CaasBilly_Prod')
);
const HomeCaaSTest = lazy(() =>
  import('../../kanda/categories/Caas/CaasBilly_ProdTest')
);
const HomeCaaSv2 = lazy(() =>
  import('../../kanda/categories/Caas/CaasBilly_AB')
);
const HomeCaaSv3 = lazy(() => import('../../kanda/categories/Caas/CaasBilly'));
const CaaSDalefon = lazy(() =>
  import('../../kanda/categories/Caas/CaaSDalefon')
);

const Core = {
  HomeCaaS,
  HomeCaaSTest,
  HomeCaaSv2,
  HomeCaaSv3,
  CaaSDalefon,
};
export default Core;
