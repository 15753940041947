import gql from 'graphql-tag';

export const GET_CATALOG_PRODUCTS_ADMIN_DEV = gql`
  query getCatalogProductsAdminDev {
    getCatalogProductsAdminDev {
      statusCode
      message
      response
    }
  }
`;

export const GET_CATALOG_PRODUCTS_ADMIN_PROD = gql`
  query getCatalogProductsAdminProd {
    getCatalogProductsAdminProd {
      statusCode
      message
      response
    }
  }
`;

export const GET_TYPE_USER_ADMIN = gql`
  query getTypeUserAdmin($IDUser: String) {
    getTypeUserAdmin(IDUser: $IDUser) {
      statusCode
      message
      response
    }
  }
`;

export const GET_PRODUCTS_ADMIN_DEV = gql`
  query getProductsAdminDev {
    getProductsAdminDev {
      statusCode
      message
      response
    }
  }
`;

export const GET_PRODUCTS_ADMIN_PROD = gql`
  query getProductsAdminProd {
    getProductsAdminProd {
      statusCode
      message
      response
    }
  }
`;

export const GET_CAT_ROUTES = gql`
  query getCatRoutesKanda($routeType: String!) {
    getCatRoutesKanda(routeType: $routeType) {
      id
      name
      path
      component
      routeType
    }
  }
`;

export const GET_CAT_ROUTES_DALEFON = gql`
  query getCatRoutesDalefon($routeType: String!) {
    getCatRoutesDalefon(routeType: $routeType) {
      id
      name
      path
      component
      routeType
    }
  }
`;

export const RECOVERY_ROUTINE_BY_ORDER = gql`
  query recoveryRoutineByOrder(
    $idTraining: Int
    $orderRoutine: Int
    $idUser: String
    $idCont: String
  ) {
    recoveryRoutineByOrder(
      idTraining: $idTraining
      orderRoutine: $orderRoutine
      idUser: $idUser
      idCont: $idCont
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_TRAINING = gql`
  query getTraining($idTraining: Int!) {
    getTraining(idTraining: $idTraining)
  }
`;

export const RECOVERY_TRAINING = gql`
  query recoveryTraining(
    $idTraining: String
    $idUser: String
    $idCont: String
  ) {
    recoveryTraining(
      idTraining: $idTraining
      idUser: $idUser
      idCont: $idCont
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NUM_ROUTINES_BY_TRAINING = gql`
  query getNumRutinesByTraining($idTraining: Int!) {
    getNumRutinesByTraining(idTraining: $idTraining)
  }
`;

export const GET_CORRECT_ANSWERS = gql`
  query getQuestionsWithCorrectAnswers($idTraining: Int!) {
    getQuestionsWithCorrectAnswers(idTraining: $idTraining) {
      response
    }
  }
`;

export const GET_MESSAGE_CAT = gql`
  query getMessageCat(
    $idQuestion: String!
    $idTypeUser: String!
    $idFieldDetail: String!
  ) {
    getMessageCat(
      idQuestion: $idQuestion
      idTypeUser: $idTypeUser
      idFieldDetail: $idFieldDetail
    ) {
      response
    }
  }
`;

export const GET_PRODUCTS_MED = gql`
  query getProductsMedTabulator($idTraining: Int!) {
    getProductsMedTabulator(idTraining: $idTraining) {
      response
    }
  }
`;

export const GET_MESSAGE_SCORE = gql`
  query getMessageScoreKanda($score: Int!, $idQuestion: Int!) {
    getMessageScoreKanda(score: $score, idQuestion: $idQuestion)
  }
`;

export const GET_MESSAGE_SCORE_INTERVAL = gql`
  query getMessageScoreInterval($score: Int!, $idQuestion: Int!) {
    getMessageScoreInterval(score: $score, idQuestion: $idQuestion)
  }
`;

export const GET_SCORE_TEST_SLIDER = gql`
  query getScoreTestSlider($idUser: Int!, $idTypes: [Int]!) {
    getScoreTestSlider(idUser: $idUser, idTypes: $idTypes) {
      response
    }
  }
`;

export const GET_CAMINOS = gql`
  query getCaminoCat($input: getCaminosInput) {
    getCaminoCat(input: $input) {
      statusCode
      message
      error
      camino {
        IdCamino
        IdType
        Order
      }
    }
  }
`;

export const GET_TRAINING_CAT = gql`
  query getCatTrainingKanda($idCatPlans: Int!, $idUser: Int!) {
    getCatTrainingKanda(idCatPlans: $idCatPlans, idUser: $idUser) {
      response
    }
  }
`;

export const GET_ALL_PLANS_KANDA = gql`
  query getAllPlansKanda {
    getAllPlansKanda
  }
`;

export const GET_USER_PLANS = gql`
  query getUserPlans($idUser: Int!) {
    getUserPlans(idUser: $idUser)
  }
`;

export const GET_USER_PT = gql`
  query getUserUrlPlanTraining($idUser: Int!) {
    getUserUrlPlanTraining(idUser: $idUser)
  }
`;

export const GET_SUM_POINTS_RECOMMENDATION = gql`
  query getSumPointsRecomendation($idUser: Int, $types: String) {
    getSumPointsRecomendation(idUser: $idUser, types: $types) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PROPERTIES_OPENFIELD = gql`
  query getCatPropertiesOpenField($IdCatQuestion: Int!) {
    getCatPropertiesOpenField(IdCatQuestion: $IdCatQuestion)
  }
`;

export const GET_PLANS_BY_ID_ROAD = gql`
  query getPlansByIdRoad($idRoad: Int!) {
    getPlansByIdRoad(idRoad: $idRoad)
  }
`;

export const GET_STATUS_VISIT_KANDA = gql`
  query getStatusVisitKanda($idUser: Int!, $idCont: Int!) {
    getStatusVisitKanda(idUser: $idUser, idCont: $idCont) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_URL_CORU_LOGIN = gql`
  query getUrlCoruLogin($appName: String!) {
    getUrlCoruLogin(appName: $appName) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_AMOUNT_BUDGET = gql`
  query getAmountBudget($idUser: Int!) {
    getAmountBudget(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_NICKNAME = gql`
  query getNicknameKanda($idUser: Int!) {
    getNicknameKanda(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_RESPONSES_PRESUPUESTO = gql`
  query getResponsePresupuesto($idUser: Int!) {
    getResponsePresupuesto(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_USER_SCORE = gql`
  query getUserScore($idUser: Int!) {
    getUserScore(idUser: $idUser)
  }
`;

export const GET_PERCENTAGE_TRAINING = gql`
  query getPercentageTraining($idUser: Int!, $idTraining: Int!) {
    getPercentageTraining(idUser: $idUser, idTraining: $idTraining) {
      percentage
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories($idUser: Int) {
    getCategories(idUser: $idUser) {
      idCategory
      nameCategory
      status
      color1
      color2
      URLCoach
      URLImage
      plans {
        id
        name
        status
        isCompleted
      }
    }
  }
`;

export const GET_PATH_KANDA = gql`
  query getPathKanda($idUser: Int!) {
    getUserPlansKanda(idUser: $idUser) {
      id
      name
      color1
      color2
      order
      score
      status
      isCompleted
    }
  }
`;

export const GET_FREEMIUNS = gql`
  query getFreemiums($idTraining: Int!) {
    getFreemiums(idTraining: $idTraining) {
      id
      status
      name
      category
      subCategory
      imageURL
      clickOut
      description
      benefits {
        id
        status
        name
        description
      }
    }
  }
`;

export const GET_DOMICILIO = gql`
  query getDomicilio($zipCode: String) {
    getDomicilio(zipCode: $zipCode) {
      State
      Municipality
      City
      Suburb
      StateAbreviation
    }
  }
`;

// CaaS
export const RECOVERY_ROUTINE_BY_ORDER_CAAS = gql`
  query recoveryRoutineByOrderCaas(
    $idTraining: Int
    $orderRoutine: Int
    $idUser: String
    $idCont: String
  ) {
    recoveryRoutineByOrderCaas(
      idTraining: $idTraining
      orderRoutine: $orderRoutine
      idUser: $idUser
      idCont: $idCont
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_TRAINING_CAAS = gql`
  query getTrainingCaas($idTraining: Int!) {
    getTrainingCaas(idTraining: $idTraining)
  }
`;

export const GET_NUM_ROUTINES_BY_TRAINING_CAAS = gql`
  query getNumRutinesByTrainingCaas($idTraining: Int!) {
    getNumRutinesByTrainingCaas(idTraining: $idTraining)
  }
`;

export const GET_PARTNER_CAAS = gql`
  query getPartnerCaaS($idPartner: Int!) {
    getPartnerCaaS(idPartner: $idPartner) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_SUCURSAL_SCOTIA = gql`
  query getSucursalScotia($data: inputSucursalScotia) {
    getSucursalScotia(data: $data) {
      statusCode
      message
      error
      sucursales {
        clave
        tipo
        name
        calle
        nExt
        colonia
        Ciudad
        Estado
        CP
        Horario
        Telefono
      }
    }
  }
`;

export const GET_PERSON_DATA = gql`
  query getPersonDataByIDCont($idcont: Int!) {
    getPersonDataByIDCont(idcont: $idcont) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_STATUS_EMAIL = gql`
  query getStatusValidationEmail($email: String!, $idGa: String) {
    getStatusValidationEmail(email: $email, idGa: $idGa)
  }
`;

export const EVEREST_VALIDATE_EMAIL = gql`
  query everestValidateEmail($email: String) {
    everestValidateEmail(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_HIDDEN_OPC = gql`
  query getCatHidden($idType: Int!, $idQuestion: Int!) {
    getCatHidden(idType: $idType, idQuestion: $idQuestion)
  }
`;

export const GET_HORIZCHECH_OPC = gql`
  query getCheckHoriz($idTypes: String!, $idQuestion: Int!) {
    getCheckHoriz(idTypes: $idTypes, idQuestion: $idQuestion)
  }
`;

export const GET_PRODUCTS_PYMES = gql`
  query getProductsPymes(
    $idUser: String
    $idTraining: String
    $mesesFact: String
  ) {
    getProductsPymes(
      idUser: $idUser
      idTraining: $idTraining
      mesesFact: $mesesFact
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_CELLPHONE_USER = gql`
  query getCellPhoneUser($email: String) {
    getCellPhoneUser(email: $email) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PROJECTS_BRIQ = gql`
  query getProjectsBriq {
    getProjectsBriq {
      statusCode
      message
      error
      response {
        name
        page_url
        url
        status
        open_for_investment
        main_image_url
      }
    }
  }
`;

export const GET_DETAIL_PROJECTS_BRIQ = gql`
  query getDetailProjectsBriq($urls: [String]) {
    getDetailProjectsBriq(urls: $urls) {
      statusCode
      message
      error
      response {
        name
        page_url
        status
        funding_goal
        minimum_successful_funding
        actual_funding
        minimum_investment_per_investor
        instrument
        interest_rate
        interest_rate_type
        time_frame
        time_frame_type
        prepayment_posibility
        warranty_type
        interest_payment_frequency
        briqmx_fee
        location
        latitude
        longitude
        developer_name
        developer_page_url
        open_for_investment
        days_left
      }
    }
  }
`;

export const GET_INTERPROT = gql`
  query getCatVerModInterProt($input: inputCatInterProt) {
    getCatVerModInterProt(input: $input) {
      statusCode
      message
      error
      response
      bestAp
    }
  }
`;

export const GET_AUTOCOMPARA = gql`
  query getCatVerModAutocompara($input: inputCatInterProt) {
    getCatVerModAutocompara(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_JSON_BASE64 = gql`
  query getJsonToBase64($json: JSON) {
    getJsonToBase64(json: $json) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_DOWNLOADABLE_TRAINING = gql`
  query getDownloadableTraining($idTraining: Int!) {
    getDownloadableTraining(idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_AFORESBY_RANKING = gql`
  query getAforesByRanking {
    getAforesByRanking {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_RENDIMIENTOS_AFORE = gql`
  query getRendimientosSiefore($edad: Int!) {
    getRendimientosSiefore(edad: $edad) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_MONITOR_DATA = gql`
  query getMonitorData($input: inputMonitor) {
    getMonitorData(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_QUESTION_BY_IDFIELD = gql`
  query getQuestionByIdField($idField: Int!) {
    getQuestionByIdField(idField: $idField) {
      IDCatQuestion
    }
  }
`;

export const GET_PRODUCTS_CAAS = gql`
  query getProductsMedCaaS($input: inputProductsCaaS) {
    getProductsMedCaaS(input: $input) {
      response
    }
  }
`;

export const GET_PRODUCTS_CATEGORIES = gql`
  query getProductsCategories {
    getCatalogoProducts {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_SESSION_USER = gql`
  query getSessionUser($idUser: String) {
    getSessionUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_CATALOGO = gql`
  query productosCatalogo {
    getProductsCatalogo {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_LAST_SESSION_BY_IDUSER = gql`
  query getLastSessionByIdUser($idUser: String!) {
    getLastSessionByIdUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_URL_TEKAE = gql`
  query getUrlTkae($email: String, $type: String) {
    getUrlTkae(email: $email, type: $type) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_REPORTE_TEKAE = gql`
  query getReportTkae($email: String, $type: String) {
    getReportTkae(email: $email, type: $type) {
      statusCode
      message
      error
      response
      datos {
        NombreDeLaEmpresa
        FolioTekae
        PAN
        TipoDeTarjeta
        Fecha
        Hora
        CarrierCompania
        Referencia
        MontoTotal
        ResultadoObservacion
        AutBanco
        AutProveedor
        UsuarioInterno
        Status
        TipoProducto
        URL
      }
    }
  }
`;
export const GET_COTIZACION = gql`
  query getTickerBitso {
    getTickerBitso {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_DISPONIBLE_PESOS = gql`
  query getDisponiblePesos($idUser: String!) {
    getDisponiblePesos(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_DISPONIBLE_USD = gql`
  query getDisponibleUSD($idUser: String!) {
    getDisponibleUSD(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_ACCOUNTS_SAVING = gql`
  query getAccountsSavingUser($idUser: String!) {
    getAccountsSavingUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_MOVEMENTS_SAVING = gql`
  query getMovementsSavingUser($idUser: String!) {
    getMovementsSavingUser(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PROFITS = gql`
  query getProfits($idUser: String!) {
    getProfits(idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_FT_PL = gql`
  query getProductsFastTrackPL($idTraining: Int!, $idUser: Int!) {
    getProductsFastTrackPL(idTraining: $idTraining, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_LEVEL_DESCRIPTIONS = gql`
  query getLevelDescription($type: String!, $idUser: String!) {
    getLevelDescription(type: $type, idUser: $idUser) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_PL_FT = gql`
  query getProductsFTPLV2($idUser: String, $idTraining: String) {
    getProductsFTPLV2(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_FAST_TRACK = gql`
  query getProductsMedFastTrack($input: inputFastTrack) {
    getProductsMedFastTrack(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_PRODUCTS_PL_FT_CREDITEA = gql`
  query getProductsFTPLCreditea($idUser: String, $idTraining: String) {
    getProductsFTPLCreditea(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;
export const GET_STATUS_VISIT_HOME = gql`
  query getCatVisitHome($idUser: Int!, $idTraining: Int!) {
    getCatVisitHome(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_FT_INGRESOS_EXTRA = gql`
  query getProductsFastTrackIngresosExtra($idTraining: Int!, $idUser: Int!) {
    getProductsFastTrackIngresosExtra(
      idTraining: $idTraining
      idUser: $idUser
    ) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_STATE = gql`
  query getState {
    getState {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_PL_FT_OTP = gql`
  query getProductsFTPLOTP($idUser: String, $idTraining: String) {
    getProductsFTPLOTP(idUser: $idUser, idTraining: $idTraining) {
      statusCode
      message
      error
      response
    }
  }
`;

export const DEEPLINK_STATUS = gql`
  query getDplinkAccesCtrl($idUser: Int!, $medium: String!, $campaign: String) {
    getDplinkAccesCtrl(idUser: $idUser, medium: $medium, campaign: $campaign) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_PRODUCTS_VALIDATION = gql`
  query getProductsValidated($input: CatalogoFilterInput) {
    getProductsValidated(input: $input) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_URL_WO_MAS = gql`
  query goNextWo($idUser: Int!) {
    goNextWo(idUser: $idUser)
  }
`;

export const CHUBB_QUOTE = gql`
  query getChubbQuote($input: inputQuoteChubb) {
    getChubbQuote(input: $input) {
      isSuccessStatusCode
      statusCode {
        code
        message
      }
      id
      dateUtc
      data {
        currency
        amount
        tax
        total
      }
    }
  }
`;
export const CHUBB_POLICY = gql`
  query getResultChubbPolicy($input: String) {
    getResultChubbPolicy(input: $input) {
      Id
      DateUtc
      IsSuccessStatusCode
      Data {
        PolicyId
        PolicyNumber
      }
    }
  }
`;
export const CHUBB_PAY = gql`
  query getUrlPayChubb($input: inputUrlPayChubb) {
    getUrlPayChubb(input: $input) {
      response
      message
      statusCode
      error
    }
  }
`;

export const GET_DESCRIPTION_SCORE = gql`
  query getDescriptionScore($name: String!, $score: String!) {
    getDescriptionScore(name: $name, score: $score) {
      tip
      message
    }
  }
`;

export const GET_ADVANCE_CAAS = gql`
  query getAvanceTrainingCaaS($idTraining: Int!, $Order: Int!, $idx: Int!) {
    getAvanceTrainingCaaS(idTraining: $idTraining, Order: $Order, idx: $idx)
  }
`;

export const GET_EMAIL_TO_SEND = gql`
  query getEmailToSend {
    getEmailToSend {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_OFERTAS_DALEFON = gql`
  query getOfertasDalefon($BToken: String!) {
    getOfertasDalefon(BToken: $BToken) {
      statusCode
      message
      error
      response
    }
  }
`;

export const GET_DOMICILIO_DALEFON = gql`
  query getDomicilioDalefon($zipCode: String) {
    getDomicilioDalefon(zipCode: $zipCode) {
      State
      IDState
      Municipality
      IDMunicipality
      City
      Suburb
      IDSuburb
      StateAbreviation
    }
  }
`;
