/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  GET_NICKNAME,
  GET_USER_SCORE,
  GET_PERSON_DATA,
  GET_SESSION_USER,
} from '../queries';
import { INSERT_SESSION_USER, UPDATE_SESSION_USER } from '../mutations';

const getItem = (key) => sessionStorage.getItem(key) || '';

export const UserContext = createContext();

function UIProvider({ children }) {
  const idUser = Number(getItem('idUser')) || 0;
  const idCont = Number(getItem('idCont')) || 0;
  const [nickName, setNickName] = useState(getItem('user_name'));
  const [userScore, setUserScore] = useState(10);
  const [userdata, setUserData] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [scoretraining, setScoreTraining] = useState(0);
  const [username1, setUserName1] = useState('');
  const [clickbutton, setClickButton] = useState(0);
  const [username2, setUserName2] = useState('');
  const [urlPymes, setUrlPymes] = useState('');
  const [fasttrackdynamic, setFastTrackDynamic] = useState('');
  const [camino, setCamino] = useState('');
  const [camino2, setCamino2] = useState(false);
  const [pdfshow, setPdfShow] = useState('IMSS');
  const [namepdf, setNamePdf] = useState(0);
  const [cardOption, setCardOption] = useState('');
  const [cardOption2, setCardOption2] = useState('');
  const [mesesFaltantes, setMesesFaltantes] = useState(0);
  const [diasFaltantes, setDiasFaltantes] = useState(0);
  const [tiempoElegido, setTiempoElegido] = useState('');
  const [montoInicial, setMontoInicial] = useState(0);
  const [questp1, setquestp1] = useState(true);
  const [questp2, setquestp2] = useState(true);
  const [questp3, setquestp3] = useState(true);
  const [fieldoption, setFieldOption] = useState(0);
  const [databc, setDataBC] = useState({});
  const [nextQuestionCC, setNextQuestion] = useState(0);
  const [imgavatar, setImgAvatar] = useState('');
  const [routineFTPL, setRoutineFTPL] = useState(1);
  const [finishedFormCI, setFinishedFormCI] = useState(0);
  const [helpanswer, setHelpAnswer] = useState('');
  const [loaderH, setLoaderH] = useState(false);
  const [activeFunc, setActiveFunc] = useState(0);
  const [nextAhorro, setNextAhorro] = useState(0);
  const [progress, setProgress] = useState(20);
  /* Dalefon */
  const [order, setOrder] = useState(1);
  const [sQuestion, setSQuestion] = useState(false);
  const [dFlujo, setDFlujo] = useState(false);
  /* ID para utm fast track */
  const [ftidx, setFTIdx] = useState('na');
  const [ftiduser, setFTIduser] = useState('na');
  const [productstori, setProductStori] = useState([]);
  const [questionfilter, setQuestionFilter] = useState([]);
  const [objcaasbilly, setCaaSBilly] = useState({});
  const [userResponseHelp, setUserResponseHelp] = useState({});
  /* ScotiaBanck v1.2 ----------------------*/
  const [scotiaview, setScotiaView] = useState({});
  const [Btnscotia, setBtnScotia] = useState(true);
  const [scotiaform, setScotiaForm] = useState({});
  const [savingform, setSavingForm] = useState({});
  const [iscaas, setIsCaaS] = useState(false);
  /*----------------------------------------*/
  //  FastTrack
  const [ingreso, setIngreso] = useState('');
  const [condition, setCondition] = useState('');
  const [idq, setIDQ] = useState(0);
  /*----------------------------------------*/
  //  cc Horóscopo
  const [insertDataUser, setInsertDataUser] = useState(false);

  // PL FT
  const [rutinaKubo, setRutineKubo] = useState([]);
  const [rutinaCreditea, setRutineCreditea] = useState([]);
  const [kuboform, setKuboForm] = useState({});
  const [rutineEscampa, setRutineEscampa] = useState([]);
  const [ocultarModalScampa, setOcultarModalScampa] = useState(false);
  // CaaS Billy
  const [Optionscontext, setOptionsContext] = useState([]);
  const [infoseguro, setInfoSeguro] = useState({});
  // Solo se ocupa para Biily SDS KandaButtonv2
  const [BillySDS, setBillySDS] = useState(false);
  const [hideText, setHideText] = useState(true);
  // Guardar WO Seguro de Salud y Seguro de Vida
  const [idroutine, setIDRoutine] = useState(0);
  const [helplife, setHelpLife] = useState({});
  const [lifeInsuranceData, setLifeInsuranceData] = useState({});
  const [lifeInsuranceQuestions, setLifeInsuranceQuestions] = useState([]);
  const [rutinapoliza, setRutinePoliza] = useState([]);
  const [formchubb, setFormChubb] = useState({});
  const [BillyStep, setBillyStep] = useState(false);
  const [ikereintentar, setIkeReintentar] = useState(false);
  /* WO HSBC */
  const [producthsbc, setProductHSBC] = useState({});
  /* WO Score */
  const [scorepuntos, setScorePuntos] = useState({
    valor: 0,
    tipo: 'sin historial',
  });

  /* Sesion activa para el usuario */
  const flagOn = !!sessionStorage.getItem('flagOn');
  const [arregloSession, setArregloSession] = useState(null);
  const [loggedUserSession] = useState(localStorage.getItem('idUser'));
  const [sessionUserOn, setSessionUserOn] = useState(null);
  const [createSessionOn, setCreateSessionOn] = useState(false);
  const [getSessionOn, setGetSessionOn] = useState(false);

  /* Manteniendo la sesión en el caso de un idUser/idCont */
  const idUserLocal = loggedUserSession;
  const idContLocal = localStorage.getItem('idCont');

  const currentLocation = window.location.pathname;

  const routeArr = [
    { pathName: '/kanda/section-tools/pago-de-servicios' },
    { pathName: '/kanda/wo/diagnosticov3' },
    { pathName: '/login' },
  ];

  const rasultIndexRoute = routeArr.findIndex(
    (route) => route.pathName === currentLocation
  );

  /* const { data: score } = useQuery(GET_USER_SCORE, {
    variables: { idUser },
  }); */

  const [score] = useLazyQuery(GET_USER_SCORE, {
    onCompleted({ getUserScore }) {
      if (getUserScore) {
        const json = JSON.parse(getUserScore);
        const { UserScoreKanda = 0 } = json;
        setUserScore(UserScoreKanda);
      }
    },
  });

  const [getSession] = useLazyQuery(GET_SESSION_USER, {
    onCompleted({ getSessionUser }) {
      if (getSessionUser.message === 'success') {
        const session = JSON.parse(getSessionUser.response);
        const arrData = JSON.parse(session);
        setArregloSession(arrData);
        setCreateSessionOn(false);
        setGetSessionOn(true);
      }
    },
  });

  const [createSessionUser] = useMutation(INSERT_SESSION_USER, {
    onCompleted({ insertSessionUser }) {
      const { response, message, error } = insertSessionUser;
      if (insertSessionUser.statusCode === 200) {
        setGetSessionOn(false);
      }
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION_USER, {
    onCompleted({ updateSessionUser }) {
      if (updateSessionUser.statusCode === 200) {
        setGetSessionOn(false);
      }
    },
  });

  const [getNickName] = useLazyQuery(GET_NICKNAME, {
    onCompleted({ getNicknameKanda }) {
      const response = JSON.parse(getNicknameKanda.response);
      const nickname = response.length ? response[0].nickname : 'Test';
      setNickName(nickname);
      sessionStorage.setItem('user_name', nickname);
    },
  });
  const [getData] = useLazyQuery(GET_PERSON_DATA, {
    onCompleted({ getPersonDataByIDCont }) {
      if (getPersonDataByIDCont.statusCode !== 400) {
        const response = JSON.parse(getPersonDataByIDCont.response);
        const data = response.length ? response[0] : 'Test';
        setUserData(data);
        const { Email1, CellPhone } = data;
        sessionStorage.setItem('Teléfono Celular', CellPhone);
        sessionStorage.setItem('Email1', Email1);
      }
    },
  });

  const updateSessionByFlag = (data) => {
    if (!data) {
      sessionStorage.setItem('idUser', loggedUserSession);
      return localStorage.clear();
    }
    sessionStorage.setItem('flagOn', 'true');
    sessionStorage.setItem('idUser', loggedUserSession);
  };

  /* useEffect(() => {
    if (score) {
      const json = JSON.parse(score?.getUserScore);
      const { UserScoreKanda = 0 } = json;
      setUserScore(UserScoreKanda);
    }
  }, [score]); */

  useEffect(() => {
    if (idUser !== 0) {
      score({ variables: { idUser } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idUser && !nickName) {
      getNickName({
        variables: {
          idUser: Number(sessionStorage.getItem('idUser')),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idCont) {
      getData({
        variables: {
          idcont: Number(sessionStorage.getItem('idCont')),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idUserLocal && idContLocal && !BillySDS) {
      sessionStorage.setItem('idUser', idUserLocal);
      sessionStorage.setItem('idCont', idContLocal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUserLocal, idContLocal]);

  useEffect(() => {
    if (
      createSessionOn ||
      (loggedUserSession !== null && loggedUserSession !== 0)
    ) {
      /* getSession({
        variables: { idUser: loggedUserSession },
      }); */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserSession, createSessionOn]);

  useEffect(() => {
    if (getSessionOn && arregloSession !== null) {
      // Obtenemos datos de la sesión del usuario
      if (arregloSession.length > 0) {
        setSessionUserOn(arregloSession[0].Flag);
        // Update de sesión activa en base al ckeckbox
        if (flagOn !== arregloSession[0].Flag && rasultIndexRoute !== -1) {
          updateSessionByFlag(flagOn);
          /* updateSession({
            variables: { idUser: loggedUserSession, Flag: flagOn },
          }); */
        } else {
          if (!arregloSession[0].Flag) {
            return localStorage.clear();
          }
          sessionStorage.setItem('flagOn', 'true');
          sessionStorage.setItem('idUser', loggedUserSession);
        }
      } else if (rasultIndexRoute !== -1) {
        // Agregar usuario en caso de no estar en BD
        /* createSessionUser({
          variables: { idUser: loggedUserSession, Flag: flagOn },
        }); */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arregloSession, getSessionOn]);

  return (
    <UserContext.Provider
      value={{
        nickName,
        userScore,
        percentage,
        userdata,
        fasttrackdynamic,
        username1,
        username2,
        urlPymes,
        camino,
        camino2,
        cardOption,
        cardOption2,
        namepdf,
        pdfshow,
        mesesFaltantes,
        diasFaltantes,
        tiempoElegido,
        montoInicial,
        questp1,
        questp2,
        questp3,
        ftiduser,
        ftidx,
        fieldoption,
        nextQuestionCC,
        databc,
        imgavatar,
        productstori,
        objcaasbilly,
        userResponseHelp,
        questionfilter,
        routineFTPL,
        scotiaview,
        Btnscotia,
        scotiaform,
        iscaas,
        ingreso,
        condition,
        scoretraining,
        infoseguro,
        BillySDS,
        clickbutton,
        rutinaCreditea,
        rutineEscampa,
        hideText,
        rutinapoliza,
        formchubb,
        finishedFormCI,
        producthsbc,
        scorepuntos,
        idq,
        BillyStep,
        arregloSession,
        sessionUserOn,
        helpanswer,
        loaderH,
        helplife,
        idroutine,
        activeFunc,
        nextAhorro,
        progress,
        ikereintentar,
        setCreateSessionOn,
        setSessionUserOn,
        setPercentage,
        setFastTrackDynamic,
        setUserName1,
        setUserName2,
        Optionscontext,
        order,
        sQuestion,
        dFlujo,
        setUrlPymes,
        setUserData,
        setCamino,
        setCamino2,
        setCardOption,
        setCardOption2,
        setPdfShow,
        setNamePdf,
        setMesesFaltantes,
        setDiasFaltantes,
        setTiempoElegido,
        setMontoInicial,
        setquestp1,
        setquestp2,
        setquestp3,
        setFTIduser,
        setFTIdx,
        setFieldOption,
        setNextQuestion,
        setDataBC,
        setImgAvatar,
        setProductStori,
        setCaaSBilly,
        setUserResponseHelp,
        setQuestionFilter,
        setRoutineFTPL,
        setScotiaView,
        setBtnScotia,
        setScotiaForm,
        setSavingForm,
        savingform,
        setIsCaaS,
        setIngreso,
        setCondition,
        setScoreTraining,
        insertDataUser,
        setInsertDataUser,
        rutinaKubo,
        setRutineKubo,
        setOptionsContext,
        setInfoSeguro,
        setBillySDS,
        setClickButton,
        kuboform,
        setKuboForm,
        setRutineCreditea,
        setRutineEscampa,
        setHideText,
        lifeInsuranceData,
        setLifeInsuranceData,
        lifeInsuranceQuestions,
        setLifeInsuranceQuestions,
        setRutinePoliza,
        setFormChubb,
        setFinishedFormCI,
        setProductHSBC,
        setScorePuntos,
        ocultarModalScampa,
        setOcultarModalScampa,
        setIDQ,
        setBillyStep,
        setArregloSession,
        setHelpAnswer,
        setLoaderH,
        setHelpLife,
        setIDRoutine,
        setActiveFunc,
        setNextAhorro,
        setProgress,
        setIkeReintentar,
        setOrder,
        setSQuestion,
        setDFlujo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UIProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UIProvider;
