/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
// Librerías
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import client from './config/apollo';
// import { Provider } from 'react-redux';
/* import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
 */
// eslint-disable-next-line import/no-unresolved
import Routes from './routes';
import 'moment/locale/es';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/fontsGeneral.css';
import UserProvider from './context/UserContext';

function App() {
  if (!sessionStorage.getItem('ID_GA')) {
    ReactGA.initialize('UA-53999647-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.ga((tracker) => {
      const idGa = tracker.get('clientId');
      sessionStorage.setItem('ID_GA', idGa);
    });
  }
  return (
    <ApolloProvider client={client()}>
      <UserProvider>
        <Router>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </UserProvider>
    </ApolloProvider>
  );
}

export default App;
