import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Core from './Exportcomponents/CoreRoutesComponents';
import { GET_CAT_ROUTES_DALEFON } from '../queries';

const CoreRoutes = () => {
  const { data: result } = useQuery(GET_CAT_ROUTES_DALEFON, {
    fetchPolicy: 'no-cache',
    variables: { routeType: 'Core' },
  });

  const Element = ({ component }) => {
    const Component = Core[component];
    return (
      <Suspense fallback={null}>
        <Component />
      </Suspense>
    );
  };

  Element.propTypes = {
    component: PropTypes.string,
  };

  return (
    <Switch>
      {result?.getCatRoutesDalefon.map((route) => (
        <Route
          exact
          path={route.path}
          component={() => Element(route)}
          key={route.id}
        />
      ))}
    </Switch>
  );
};

export default CoreRoutes;
